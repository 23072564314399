<template>
  <div class="player-profile-view">
    <div class="main-panel">
      <PageHeaderKiosk/>
      <div class="text-center">
        <h1>Player Profile</h1>
      </div>
      <div class="container-fluid">
        <div class="row mx-0 mt-2 mb-4 py-3 border-bottom border-secondary">
          <div class="col m-0 p-0 text-left">
            <div>
              <h3>{{this.displayName}}</h3>
              <!--<h3 @click="onEdit()">{{this.displayName}}
                <span class="material-icons icon text-primary" 
                  style="font-size:x-large;">settings</span> 
              </h3>-->
            </div>
            <div><h5 class="text-secondary">{{fullName}}</h5>
            </div>
            <div class="text-secondary">RFID {{this.customId}}</div>
          </div>
          <div class="col-auto m-0 p-0">
            <div class="flex-fill align-self-start w-100 text-right pt-2">
              <b-avatar :style="`background-color: ${this.avatarColor};`"
                :text="userInitials" size="5rem" class="m-0"/>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col text-left">
            <h5>Statistics</h5>
          </div>
        </div>
        <div class="row mb-4">
          <div @click="onLeaderboard()" class="col">
            <div class="border border-3 border-secondary rounded p-2">
              <div class="py-0"><h3><span class="text-secondary"># </span>{{leaderboard?leaderboard.position: 0}}</h3></div>
              <div class="text-info">Position</div>
            </div>
          </div>
          <div @click="onLeaderboard()" class="col">
            <div class="border border-3 border-secondary rounded p-2">
              <div><h3>{{leaderboard?leaderboard.value: 0}}</h3></div>
              <div class="text-info">Score</div>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col">
            <div class="container border border-3 border-secondary rounded p-2">
              <div class="row" v-for="stat in activationStats" :key="stat.name">
                <div class="col text-right align-self-center"><h5 class="text-info" @click="onSingleLeaderboard(stat.id, stat.displayName)">{{stat.displayName}}</h5></div>
                <div class="col"><h3 @click="onSingleLeaderboard(stat.id, stat.displayName)">{{stat.value}}{{stat.name}}</h3></div>
              </div>
            </div>
          </div>
        </div>

        <!-- <button type="button" class="btn btn-outline-primary btn-large btn-block my-2"
          @click="onEdit()"><h3>Edit Player Profile</h3></button> -->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeaderKiosk from '@/components/PageHeaderKiosk.vue'
import { getRandomInt } from '@/common/number.helper';
import { PLAYERS_INITIALS, PLAYERS_GET_LEADERBOARD, PLAYERS_GET_STATISTICS } 
  from '@/store/modules/players.store'
import { LEADERBOARDS_GET_ALL } from '@/store/modules/leaderboards.store'
  
import { PlayersService } from '@/services/players.service';
import { StringUtils } from "@/common/string.utils";
import Vue from 'vue';

export default {
  name: 'PlayerProfileView',
  components: {
    PageHeaderKiosk,
  },
  data() {
    return {
      id: null,
      customId: '',
      displayName: '',
      firstName: '',
      lastName: '',
      createdAt: null,
      player: null,
      statistics: [],
      leaderboard: null,
    }
  },
  created() {

    // this.id = this.$route.params.id;
    this.getPlayer();
    if (!this.id) {
      this.$router.push(`/home`)
    }
    this.getPlayerLeaderboard(this.id);
    this.getPlayerStatistics(this.id);
    this.getLeaderboards();
  },
  computed: {
    userInitials () {
      const initials = this.$store.getters[PLAYERS_INITIALS];
      return initials? initials : '?';
    },
    fullName () {
      let name = '';
      if (this.player) {
        name = (`${this.player.firstName?this.player.firstName:''} ${this.player.lastName?this.player.lastName:''}`).trim();
      }
      return name? name : '(no name)';
    },
    avatarColor () {
      return StringUtils.toColor(this.displayName);
    },
    joinedDate () {
      return new Date(this.createdAt).toDateString().slice(4);
    },
    activationStats () {
      const stats = this.$store.state.leaderboards.leaderboards.filter((lb) => {
         return lb.name.toUpperCase().startsWith('ASCORE') && lb.isActive;
      })
      .map((lb) => {
        const stat = this.statistics.find((s) => s.name === lb.name);
        return {displayName: lb.displayName, value: stat? stat.value : '-', id: lb._id}
      });
      return stats;
    }
  },
  methods: {
    getPlayer() {
      // get the player that has been logged in in the $store from the last
      // player scan.
      this.player = JSON.parse(JSON.stringify(this.$store.state.players.player));
      this.id = this.player._id;
      this.customId = this.player.customId;
      this.displayName = this.player.displayName;
      this.firstName = this.player.firstName;
      this.lastName = this.player.lastName;
      this.createdAt = this.player.createdAt;
    },
    getPlayerLeaderboard(id) {
      this.$store.dispatch(PLAYERS_GET_LEADERBOARD, {'id': id, 'leaderboardName': 'Score'})
        .then(() => {
          this.leaderboard = JSON.parse(JSON.stringify(this.$store.state.players.leaderboard));
        });
    },
    getPlayerStatistics(id) {
      this.$store.dispatch(PLAYERS_GET_STATISTICS, id)
        .then(() => {
          this.statistics = JSON.parse(JSON.stringify(this.$store.state.players.statistics));
        });
    },
    getLeaderboards() {
      if (this.$store.state.leaderboards.leaderboards.length === 0) {
        this.$store.dispatch(LEADERBOARDS_GET_ALL);
      }
    },
    async onNicknameRandom() {
      const res = await PlayersService.getDisplayName(3);
      if (res.data.displayName) {
        this.displayName = res.data.displayName;
      }
    },
    onNicknameByName() {
      const first = this.firstName? this.firstName : '';
      const last = this.lastName? this.lastName.charAt(0) : '';
      let numStr = '';
      if (first + last) {
        numStr = ('' + getRandomInt(0, 999)).padStart(3, '0');
      }
      const nickname = `${first}${last}${numStr}`;
      if (nickname) {
        this.displayName = nickname;
      }
    },
    onEdit() {
      Vue.prototype.playAudio('button');
      this.$router.push(`/playeredit`)
    },
    onLeaderboard() {
      this.$router.push(`/leaderboard`)
    },
	 onSingleLeaderboard(id, displayName) {
      this.$router.push({
          name: `Leaderboard`,
          params: {
            leaderboard: {'_id': id, 'displayName': displayName},
          }
      });
    },									  
  }
}
</script>

<style scoped>
h1 {
  text-transform: uppercase;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
</style>
